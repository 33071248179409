.notificationRoot {
  position: fixed;
  z-index: 20000;
  outline: none;
  width: 100%;
  height: 45px;
  -webkit-box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  padding: 0 4px;
  font-size: 14px !important;
  color: #ccc !important;
  background-color: #303030;
  border-radius: 4px 4px 0 0;
}
